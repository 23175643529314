<template>
    <div>
        <div v-if="fetchingFile">
            <v-skeleton-loader type="article@2"></v-skeleton-loader>
            <v-skeleton-loader type="paragraph" class="ml-4"></v-skeleton-loader>
        </div>
        <viewer v-else style="overflow-x: auto" @load="loaded" ref="toastuiEditor" :options="defaultOptions" :initialValue="editorText" height="500px" />
    </div>
</template>

<script>
import '@toast-ui/editor/dist/toastui-editor-viewer.css'
import { Viewer } from '@toast-ui/vue-editor'
import { toastEditor } from '@/mixins/toastEditor'

export default {
    name: 'MarkdownViewer',
    mixins: [toastEditor],
    components: {
        Viewer
    },
    watch: {
        editorText: function (nextVal) {
            this.$refs.toastuiEditor.invoke('setMarkdown', nextVal)
        }
    }
}
</script>
